import React from "react"
import Layout from "../../components/layout"

export default function ThankYouPage() {
  return (
    <Layout>
      <div className="space-y-4">
        <h2>Thank you for your registration!</h2>
        <p>
          More details on venue and detailed agenda will be sent out to you by
          mid-March at the latest.
        </p>
        <p>Looking forward to welcoming you in Malmö in April!</p>
      </div>
    </Layout>
  )
}
